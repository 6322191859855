// import i18n from '@/i18n/index'

export default {
  general: [{
    prop: 'id'
  }, {
    prop: 'name'
  }, {
    prop: 'no_of_phases'
  }, {
    prop: 'max_current_per_phase'
  }, {
    prop: 'min_current_per_charge_point'
  }, {
    prop: 'set_charger_limit_to_zero_when_idle'
  }],
  chargingPoint: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '80'
  }, {
    prop: 'name',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'system_status',
    label: '',
    Object: 'value',
    width: '180',
    redBg: true
  }, {
    prop: 'charge_point_type',
    label: '',
    Object: 'value',
    width: '200'
  }, {
    prop: 'location',
    label: '',
    Object: 'value',
    width: '200'
  }, {
    prop: 'circuit',
    label: '',
    Object: 'value',
    width: '130'
  }, {
    prop: 'evses',
    label: '',
    Object: 'value',
    width: '130'
  }],
  circuit_priorities: [{
    prop: 'id',
    label: 'ID',
    Object: 'value',
    width: '80'
  }, {
    prop: 'name',
    label: 'User Group',
    Object: 'value',
    width: '120'
  }, {
    prop: 'priority',
    label: 'Priority',
    Object: 'value',
    width: '120'
  }],
  dialogData: [{
    key: 'priority',
    value: '',
    name: 'Priority',
    type: 'text',
    hint: 'Priority',
    Object: {
    }
  }]
}
