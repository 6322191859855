<template>
  <div class="circuitsDetail">
    <div class="msg-box">
      <header>
        <div class="msg-box-title">Virtual Circuit Max Current</div>
        <div class="msg-box-count">150A </div>
      </header>
      <header>
        <div class="msg-box-title">Realtime Current</div>
        <div class="msg-box-count">21.3A </div>
      </header>
    </div>
    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="General" name="general">
          <custome-table tableType="object" :custSpan="6" :data="custName" :dataItem="jsonData[activeName]"></custome-table>
        </el-tab-pane>
        <el-tab-pane label="Charge Points" name="chargingPoint">
          <chargingPointVue></chargingPointVue>
        </el-tab-pane>
        <el-tab-pane label="User Priority" name="circuit_priorities">
          <userProrityVue></userProrityVue>
          <!-- <custome-table tableType="object" :custSpan="6" :data="custName" :dataItem="jsonData[activeName]" @forwardToPage="forwardToPage"></custome-table> -->
          <!-- <createTable @forwardToPage="forwardToPage" :autoWidth="true" :tableDataItem="tableDataItem_circuit" :operateVisible="false" :tableData="circuit_priorities"></createTable> -->
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import loading from '@/utils/loading.json'
import customeTable from '@/components/customeTable'
import chargingPointVue  from './component/chargingPoint.vue'
import userProrityVue from './component/userPrority.vue'
import jsonData from './jsonData'
import apis from '@/services/index'
import { mixin } from '@/mixins/viewDiolog'

export default {
  components: { customeTable, chargingPointVue, userProrityVue  },
  mixins: [mixin],
  data () {
    return {
      custName: {},
      jsonData: jsonData,
      // basicInformation: jsonData.basicInformation,
      name: 'generalVue',
      activeName: 'general',
      charge_points: [],
      circuit_priorities: [],
      tableDataItem_circuit: [{
        prop: 'circuit_priorities',
        label: 'User Priority',
        Object: 'value',
        width: '80'
      }]
    }
  },
  beforeRouteLeave (to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
    next()
  },
  created () {
    this.init()
  },
  methods: {
    // 初始化
    async init () {
      this.$store.state.app.loading = this.$loading(loading)
      const res = await apis.SynamicLoad.getCircuitsDetail(this.$route.params.id)
      if (res.status === 200) {
        this.custName = res.data.circuit
        this.charge_points = [{
          charge_points: res.data.circuit.charge_points
        }]
        this.circuit_priorities = [{
          circuit_priorities: res.data.circuit.circuit_priorities
        }]
      }
      this.$store.state.app.loading.close()
    },
    forwardToPage ({ model_name, id }, name) {
      if (model_name && model_name === 'charge_point') {
        this.$router.push({ name: 'chargingPointDetail', params: { id } })
      }
    },
    gobackRouter () {
      this.$router.go(-1)
    },
    // 点击操作按钮
    async handleClick (type, data) {

    }
  }
}
</script>

<style lang="scss" scope>
.circuitsDetail {
  .msg-box {
    display: flex;
    flex-direction: column;
    width: 380px;
    height: auto;
    background-color: white;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 16px 24px;
    &-title {
      font-size: 1rem;
      font-weight: 600;
      color: #666;
    }
    &-count {
      font-size: 2rem;
      color: #6894D4;
      padding: 30px 0;
      font-weight: 600;
      display: flex;
      align-items: center;
    }
    &-item {
      display: flex;
      font-size: .6rem;
      font-weight: 600;
      color: #666;
      li {
        margin-right: 20px;
      }
      &-count {
        color: #6894D4;
        padding-top: 10px;
      }
    }
  }
}
.managerUserOrderView {
  .del-oder-item-dialog-body {
    .heder {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      .avatar {
        flex-shrink: 0;
      }
    }
    .info-box {
      margin-left: 12px;
      margin-bottom: -12px;
      .title {
        margin-bottom: 12px;
      }
    }
    .notice {
      margin-bottom: 12px;
    }
  }
}
</style>
