<template>
<div>
  <createTable
  ref="createTable"
  :operationColWidth="150"
  :autoWidth="true"
  :loading="loading"
  :pageVisible="false"
  :tableData="tableData"
  :tableDataItem="tableDataItem"
  @handleClick="handleClick"
  @forwardToPage="forwardToPage">
    <template #rightBtn="scope">
      <el-button class="margintop10" :class="item.action === 'view' ? 'operate_view' : ''" v-for="(item, index) in scope.row.member_actions.default_actions" :key="'default_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleClick(item, scope.row)">{{item.name}}</el-button>
       <el-button class="margintop10" v-for="(item, index) in scope.row.member_actions.custom_actions" :key="'custom_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleCusClick(item)">{{item.name}}</el-button>
    </template>
  </createTable>
  <dialogEffect ref="'dialogForm" v-if="dialogVisible" :opereteDate="false" :todayVisible="true" :dialogVisible.sync="dialogVisible" :data="dialogData" @doNextConfirm="dialogConfirm"></dialogEffect>

</div>
</template>

<script>
import apis from '@/services'
import createTable from '@/components/createTable'
import dialogEffect from '@/components/dialogEffect'
import jsonData from '../jsonData'
import { mixin } from '@/mixins/mixin'

export default {
  components: { createTable, dialogEffect },
  mixins: [mixin],
  data () {
    return {
      dialogVisible: false,
      dialogData: jsonData.dialogData,
      tableDataItem: jsonData.circuit_priorities,
      currId: null
    }
  },
  created () {
    // this.queryData(apis.AssetApi.getChargePointSetup)
    this.search()
  },
  methods: {
    search () {
      this.searchData.circuit_id_eq = this.$route.params.id
      this.loading = true
      apis.SynamicLoad.getUserProirity(this.searchData).then(res => {
        if (res.data) {
          this.tableData = res.data.objects
          this.tabData = res.data.scopes
        }
      }).finally(() => {
        this.loading = false
      })
    },
    async handleClick (type, data) {
      if (type?.action === 'edit') {
        this.dialogData[0].value = data.priority.value
        this.currId = data.id.value
        this.dialogVisible = true
      }
      if (type?.action === 'destroy') {
        this.deleteClick(data, apis.SynamicLoad.deleteUserProirity)
      }
    },
    forwardToPage ({ model_name, id }, name) {

    },
    dialogConfirm (circuit_priority) {
      const data = {
        circuit_priority
      }
      apis.SynamicLoad.getUserProirityEdit(this.currId, data).then(() => {
        this.search()
      })
    }
  }
}
</script>
<style lang="scss" scope>

</style>
